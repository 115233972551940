import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    Array.from(this.linkTargets, (link) =>
      link.addEventListener("click", () => {
        window.location.href = link.dataset.href;
      }),
    );
  }
}
