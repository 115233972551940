import { Sortable } from "@shopify/draggable";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "selected", "input", "form"];

  connect() {
    new Sortable(this.containerTargets, {
      draggable: ".draggable-item",
      mirror: {
        appendTo: ".draggable-list",
        constrainDimensions: true,
      },
    });
  }

  /** submitForm - handle the related users before saving
   *
   * the sortable (and draggable) library emits the `drag:stop` method _before_
   * adding the new DOM node, so counting the elements for ordering is unreliable
   *
   * if we do it here we ensure the order
   */
  submitForm(e) {
    e.preventDefault();

    const userItems = this.selectedTarget.querySelectorAll(".draggable-item");
    const userIds = [...userItems].map((userItem) => userItem.dataset.userId);

    userIds.forEach((userId) => {
      const newInput = this.inputTarget.cloneNode();
      newInput.value = userId;
      this.formTarget.append(newInput);
    });

    this.formTarget.submit();
  }
}
