/* global $ */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "discountsTable",
    "selectAllDiscounts",
    "selectAllSubscriptionPlans",
    "subscriptionDiscountCheckbox",
  ];

  connect() {
    this.bindSelectAllDiscountsLinks();
    this.bindSelectAllSubscriptionPlansLinks();
    this.bindSubscriptionDiscountCheckboxes();
  }

  bindSelectAllDiscountsLinks() {
    Array.from(this.selectAllDiscountsTargets).forEach((elem) => {
      elem.addEventListener("click", (evt) => {
        this.queryAllDiscountCheckboxesFor(
          elem.dataset.subscriptionPlanId,
        ).forEach((checkbox) => {
          checkbox.checked = evt.target.checked;
        });
      });
    });
  }

  bindSelectAllSubscriptionPlansLinks() {
    Array.from(this.selectAllSubscriptionPlansTargets).forEach((elem) => {
      elem.addEventListener("click", (evt) => {
        this.queryAllSubscriptionPlanCheckboxesFor(
          elem.dataset.discountId,
        ).forEach((checkbox) => {
          checkbox.checked = evt.target.checked;
        });
      });
    });
  }

  bindSubscriptionDiscountCheckboxes() {
    Array.from(this.subscriptionDiscountCheckboxTargets).forEach((elem) => {
      elem.addEventListener("click", () => {
        const allDiscountsChecked = this.queryAllDiscountCheckboxesFor(
          elem.dataset.subscriptionPlanId,
        ).every((checkbox) => checkbox.checked);

        this.discountsTableTarget.querySelector(
          `[data-target="backend--event-discounts.selectAllDiscounts"][data-subscription-plan-id="${elem.dataset.subscriptionPlanId}"]`,
        ).checked = allDiscountsChecked;

        const allSubscriptionPlanChecked =
          this.queryAllSubscriptionPlanCheckboxesFor(
            elem.dataset.discountId,
          ).every((checkbox) => checkbox.checked);

        this.discountsTableTarget.querySelector(
          `[data-target="backend--event-discounts.selectAllSubscriptionPlans"][data-discount-id="${elem.dataset.discountId}"]`,
        ).checked = allSubscriptionPlanChecked;
      });
    });
  }

  queryAllDiscountCheckboxesFor(subscriptionPlanId) {
    return Array.from(
      this.discountsTableTarget.querySelectorAll(
        `[data-target="backend--event-discounts.subscriptionDiscountCheckbox"][data-subscription-plan-id="${subscriptionPlanId}"]`,
      ),
    );
  }

  queryAllSubscriptionPlanCheckboxesFor(discountId) {
    console.log("queryAllSubscriptionPlanCheckboxesFor", discountId);
    return Array.from(
      this.discountsTableTarget.querySelectorAll(
        `[data-target="backend--event-discounts.subscriptionDiscountCheckbox"][data-discount-id="${discountId}"]`,
      ),
    );
  }
}
