import { Controller } from "stimulus";

import loadScript from "~/lib/asyncScriptLoader";
import GoogleGeocomplete, { placesUri } from "~/lib/GoogleGeocomplete";

export default class extends Controller {
  static targets = ["container", "input", "components", "formattedAddress"];

  connect() {
    loadScript(placesUri).then(
      () => {
        const widget = GoogleGeocomplete(this.inputTarget, {
          componentRestrictions: { country: ["au", "nz"] },
        });

        widget.on("geocomplete:result", (event, components) => {
          Object.entries(components).forEach(([name, value]) => {
            const input = this.containerTarget.querySelector(
              `[data-places-name='${name}']`,
            );

            if (!input) return;

            input.value = value;
          });
          this.formattedAddressTarget.value = this.inputTarget.value
            .split(", ")
            .slice(0, -1)
            .join("\n");
        });
      },
      () => {
        // console.log("fail to load script")
      },
    );
  }

  toggleComponents() {
    this.componentsTarget.classList.toggle("hidden");
    this.componentsTarget.classList.toggle("block");
  }
}
