import { Controller } from "stimulus";

import FlashMessages from "~/lib/FlashMessages";

export default class extends Controller {
  static targets = ["source", "target"];

  connect() {
    FlashMessages();
  }
}
