import { Controller } from "stimulus";

import {
  convertResponse,
  makeDELETERequest,
  makePOSTRequest,
} from "~/lib/apiRequests";
import ConfirmModal from "~/lib/ConfirmModal";

export default class extends Controller {
  // A generic POST request, which replaces the closest row with the response HTML
  async postRequest(e) {
    e.preventDefault();
    const { response } = await makePOSTRequest(e.target.href, {
      ...e.target.dataset,
    }).then(convertResponse);

    const row = e.target.closest(e.target.dataset.selector || `tr`);
    row.outerHTML = response.html;
  }

  // A generic DELETE request, which replaces the closest row with the response HTML
  async deleteRequest(e) {
    e.preventDefault();

    const { response } = await makeDELETERequest(e.target.href).then(
      convertResponse,
    );

    const row = e.target.closest(e.target.dataset.selector || `tr`);
    row.outerHTML = response.html;
  }

  async deleteWithConfirm(e) {
    e.preventDefault();

    ConfirmModal({
      selector: "#confirm-delete",
      title: `Delete ${e.currentTarget.dataset.resource}`,
      content: "Are you sure?",
      confirmDismiss: true,
      confirmCallback: () => {
        this.deleteRequest(e);
      },
    });
  }

  async preview(e) {
    e.preventDefault();

    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: e.target.href,
    }).click();
  }
}
