import stickybits from "stickybits";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stickyContainer", "stickyContent"];

  connect() {
    // In some cases the container height is not high enough (eg content in two
    // side by side columns)
    Array.from(this.stickyContainerTargets).forEach((elem) => {
      elem.style.height = "100%";
    });

    const selector = this.stickyContentTarget.className.replace(" ", ".");
    const offset = this.stickyContentTarget.dataset.stickyOffset;
    stickybits(`.${selector}`, { stickyBitStickyOffset: offset });
  }
}
