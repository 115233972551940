import { Controller } from "stimulus";

import {
  convertResponse,
  defaultJSONHeaders,
  makeFetchRequest,
} from "~/lib/apiRequests";

export default class extends Controller {
  static targets = ["form", "details", "role", "vCardUrl"];

  connect() {
    this.toggleVCardUrl(this.roleTarget.value);
  }

  async postRequest(e) {
    e.preventDefault();

    const params = new FormData(this.formTarget);

    const { response } = await makeFetchRequest(this.formTarget.action, {
      method: "post",
      params,
      headers: defaultJSONHeaders,
    }).then(convertResponse);

    this.detailsTarget.innerHTML = response.html;
  }

  changeRole(e) {
    this.toggleVCardUrl(e.target.value);
  }

  toggleVCardUrl(value) {
    if (value === "admin" || value === "employee") {
      this.vCardUrlTarget.classList.replace("hidden", "block");
    } else {
      this.vCardUrlTarget.classList.replace("block", "hidden");
    }
  }
}
