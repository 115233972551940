/* global $ */
/* global grecaptcha */
import { Modal } from "bootstrap";
import { Controller } from "stimulus";

import { convertResponse, makeFetchRequest } from "~/lib/apiRequests";
import loadScript from "~/lib/asyncScriptLoader";

export default class extends Controller {
  static targets = [
    "bookingForm",
    "briefForm",
    "contactForm",
    "contactModal",
    "notifiableIdInput",
    "notifiableTypeInput",
    "formMessage",
    "modalMessage",
    "needTextArea",
    "toolForm",
  ];

  connect() {
    // Define the callback that the recaptcha script calls
    window.onloadCallback = function onloadCallback() {
      // Setup recaptcha button for submitting modal
      if (this.hasContactModalTarget) {
        const modalButton = this.contactModalTarget.querySelector(".recaptcha");
        this.modalRecaptcha = grecaptcha.render(modalButton, {
          site_key: modalButton.dataset.sitekey,
          callback: this.recaptchaSubmitModal.bind(this),
          size: "invisible",
        });
      }

      // Setup recaptcha button for submitting form
      if (this.hasContactFormTarget) {
        const formButton = this.contactFormTarget.querySelector(".recaptcha");
        this.formRecaptcha = grecaptcha.render(formButton, {
          site_key: formButton.dataset.sitekey,
          callback: this.recaptchaSubmitForm.bind(this),
          size: "invisible",
        });
      }

      // Setup recaptcha button for brief form
      if (this.hasBriefFormTarget) {
        const briefButton = this.briefFormTarget.querySelector(".recaptcha");
        this.briefRecaptcha = grecaptcha.render(briefButton, {
          site_key: briefButton.dataset.sitekey,
          callback: this.recaptchaSubmitBrief.bind(this),
          size: "invisible",
        });
      }

      // Setup recaptcha button for booking form
      if (this.hasBookingFormTarget) {
        const registerButton =
          this.bookingFormTarget.querySelector(".recaptcha");
        this.eventRecaptcha = grecaptcha.render(registerButton, {
          site_key: registerButton.dataset.sitekey,
          callback: this.recaptchaSubmitBooking.bind(this),
          size: "invisible",
        });
      }

      // Setup recaptcha button for tool forms
      if (this.hasToolFormTarget) {
        const toolSubmitButton =
          this.toolFormTarget.querySelector(".recaptcha");
        this.toolRecaptcha = grecaptcha.render(toolSubmitButton, {
          site_key: toolSubmitButton.dataset.sitekey,
          callback: this.recaptchaSubmitToolForm.bind(this),
          size: "invisible",
        });
      }
    }.bind(this);

    // Async load the recaptcha script then execute callback
    loadScript(
      "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit",
      this.contactModalTarget,
    ).then(
      () => {},
      () => {},
    );
  }

  recaptchaSubmitForm(token) {
    const form = this.contactFormTarget.querySelector("form");
    this.submitForm(form, token, this.formRecaptcha);
  }

  recaptchaSubmitModal(token) {
    const form = this.contactModalTarget.querySelector("form");
    this.submitModal(form, token, this.modalRecaptcha);
  }

  recaptchaSubmitBrief() {
    const form = this.briefFormTarget.querySelector("form");
    form.submit();
  }

  recaptchaSubmitBooking() {
    const form = this.bookingFormTarget.querySelector("form");
    form.submit();
  }

  recaptchaSubmitToolForm() {
    const form = this.toolFormTarget.querySelector("form");
    form.submit();
  }

  setNotifiable({ target }) {
    const { notifiableId, notifiableType } = target.dataset;
    this.notifiableIdInputTarget.value = notifiableId;
    this.notifiableTypeInputTarget.value = notifiableType;
  }

  async submit(form, token, recaptchaId) {
    form.classList.add("was-validated");

    const formData = new FormData(form);
    formData.append("g-recaptcha-response", token);

    const { response, errors } = await makeFetchRequest(form.action, {
      method: "POST",
      body: formData,
    }).then(convertResponse);

    if (response) {
      form.classList.remove("was-validated");
      form.reset();
    } else {
      Object.entries(errors).forEach(([key, [errorMsg]]) => {
        const input = form.querySelector(`[name*='${key}']`);
        input.parentNode.querySelector(".invalid-feedback").innerHTML =
          errorMsg;
      });

      grecaptcha.reset(recaptchaId);
    }

    return { response, errors };
  }

  async submitForm(form, token, recaptchaId) {
    const message = this.formMessageTarget;

    const { response } = await this.submit(form, token, recaptchaId);

    if (response) {
      message.innerHTML = response.data.message;
      message.classList.toggle("hidden");

      setTimeout(() => {
        message.classList.toggle("hidden");
      }, 2500);
    }
  }

  async submitModal(form, token, recaptchaId) {
    const message = this.modalMessageTarget;

    const { response } = await this.submit(form, token, recaptchaId);

    if (response) {
      message.innerHTML = response.data.message;
      form.classList.add("hidden");
      message.classList.remove("hidden");

      setTimeout(() => {
        const modalEl = document.getElementById("contact-modal");
        const modal = Modal.getInstance(modalEl);
        modal.hide();
      }, 1500);

      this.contactModalTarget.addEventListener("hidden.bs.modal", () => {
        form.classList.remove("hidden");
        message.classList.add("hidden");
      });
    }
  }
}
