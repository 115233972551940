import { Controller } from "stimulus";
import slugify from "underscore.string/slugify";

export default class extends Controller {
  static targets = ["sluggable", "slug"];

  generateSlug(e) {
    this.slugTarget.value = slugify(e.currentTarget.value);
  }
}
