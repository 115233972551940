import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const notyf = new Notyf({
  dismissible: false,
  duration: 5000,
  position: {
    x: "center",
    y: "top",
  },
  types: [
    {
      type: "error",
      background: "hsl(var(--danger))",
      className: "font-heading",
    },
    {
      type: "info",
      background: "hsl(var(--accent))",
      className: "font-heading text-accent-foreground",
      icon: {
        className: "fa-solid fa-circle-info",
        color: "hsl(var(--accent-foreground))",
      },
    },
    {
      type: "success",
      background: "hsl(var(--success))",
      className: "font-heading",
    },
  ]
})

const defaultFlashOptions = () => ({});

// Map Rails's "alert" to "error"
const mapFlashType = (type) => {
  if (type === "alert") return "error";
  if (type === "notice") return "info";
  return type;
}

const flash = (message, type) => {
  const mappedType = mapFlashType(type)
  if (mappedType === "success") {
    notyf.success(message);
  } else if (mappedType === "error") {
    notyf.error(message)
  } else {
    notyf.open({ type: mappedType, message })
  }
};

// Custom flash message handling (for AJAX/manual calls)
const customFlashOptions = (options = {}) => ({
  ...defaultFlashOptions(),
  ...options,
});

export default function FlashMessages(customFlash) {
  if (!customFlash || Object.keys(customFlash).length === 0) {
    const viewFlash = document.getElementById("flash");
    if (viewFlash) {
      const message = viewFlash.dataset.message;
      const type = viewFlash.dataset.type ?? "info"
      flash(message, type);
    }
  } else {
    const message = customFlash.options.message;
    const type = customFlash.settings.type ?? "info"
    flash(message, type);
  }
};
