import { TempusDominus } from "@eonasdan/tempus-dominus";
import { Controller } from "stimulus";

import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css";

export default class extends Controller {
  static targets = [
    "datePickerInput",
    "dateTimePickerInput",
    "timePickerInput",
  ];

  connect() {
    Array.from(this.datePickerInputTargets).forEach(
      (input) =>
        new TempusDominus(input, {
          localization: { format: "dd/MM/yyyy" },
        }),
    );

    Array.from(this.dateTimePickerInputTargets).forEach(
      (input) =>
        new TempusDominus(input, {
          localization: { format: "dd/MM/yyyy hh:mm T" },
          stepping: 30,
        }),
    );

    Array.from(this.timePickerInputTargets).forEach(
      (input) =>
        new TempusDominus(input, {
          localization: { format: "LT" },
          stepping: 30,
        }),
    );
  }
}
