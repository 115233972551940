import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "subDropdown", "toggleElem", "toggleTarget"];

  connect() {
    // Initially hide toggle targets
    this.toggleTargetTargets.forEach((input) => {
      input.style.display = "none";
    });

    // Initially hide sub-dropdowns
    this.subDropdownTargets.forEach((dropdown) => {
      dropdown.style.display = "none";
    });

    // Attach click event to all menu elements for sub-dropdown
    this.menuTargets.forEach((menu) => {
      menu.addEventListener("click", (e) => this.toggleSubDropdown(e, menu));
    });
  }

  toggle(e) {
    const targetElem = e.currentTarget.nextElementSibling;
    this.slideToggle(targetElem);
  }

  toggleSubDropdown(event, menu) {
    event.stopPropagation();
    event.preventDefault();

    // Find the next sibling sub-dropdown related to the clicked menu item
    const subDropdown = menu.nextElementSibling;

    // Ensure the next element is a sub-dropdown
    if (subDropdown && subDropdown.classList.contains("sub-dropdown")) {
      this.slideToggle(subDropdown);
    }
  }

  slideToggle(element, duration = 500) {
    if (window.getComputedStyle(element).display === "none") {
      this.slideDown(element, duration);
    } else {
      this.slideUp(element, duration);
    }
  }

  slideDown(element, duration) {
    element.style.removeProperty("display");
    element.style.display = "block";

    const height = element.scrollHeight;
    element.style.overflow = "hidden";
    element.style.height = 0;

    // Force a reflow to apply the height
    element.offsetHeight;
    element.style.transitionProperty = "height";
    element.style.transitionDuration = `${duration}ms`;
    element.style.height = `${height}px`;
    element.style.boxSizing = "border-box";

    window.setTimeout(() => {
      element.style.removeProperty("height");
      element.style.removeProperty("overflow");
      element.style.removeProperty("transition-duration");
      element.style.removeProperty("transition-property");
    }, duration);
  }

  slideUp(element, duration) {
    // First, store the current height of the element
    const height = element.scrollHeight;

    if (height === 0) return;

    // Set the initial height to the element's current height
    element.style.height = `${height}px`;

    // Force a reflow to apply the height
    element.offsetHeight;

    element.style.boxSizing = "border-box";
    element.style.transitionProperty = "height";
    element.style.transitionDuration = `${duration}ms`;
    element.style.overflow = "hidden";
    element.style.height = 0;

    window.setTimeout(() => {
      element.style.display = "none";
      element.style.removeProperty("height");
      element.style.removeProperty("overflow");
      element.style.removeProperty("transition-duration");
      element.style.removeProperty("transition-property");
    }, duration);
  }
}
