import { Controller } from "stimulus";
import TomSelect from "tom-select/dist/js/tom-select.base"
import "tom-select/dist/css/tom-select.bootstrap5.css"

export default class extends Controller {
  static targets = ["input", "inputWithOnChange", "onChangeElement"];

  connect() {
    // Initialize TomSelect for elements without onChange
    this.inputTargets.forEach((input) => {
      new TomSelect(input, {
        delimiter: ", ",
        create: (inputValue) => ({
          value: inputValue,
          text: inputValue,
        }),
      });
    });

    // Initialize TomSelect for elements with onChange
    this.inputWithOnChangeTargets.forEach((input) => {
      new TomSelect(input, {
        delimiter: ", ",
        create: (inputValue) => ({
          value: inputValue,
          text: inputValue,
        }),
        onChange: this.onChange, // TomSelect's onChange event
      });
    });
  }

  onChange = () => {
    this.onChangeElementTarget.click(); // Trigger the click on the target element when value changes
  };
}
