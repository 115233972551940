import { Controller } from "stimulus";

import {
  convertResponse,
  defaultJSONHeaders,
  makeFetchRequest,
} from "~/lib/apiRequests";

export default class extends Controller {
  static targets = ["form", "button", "loadingIndicator", "resources"];

  async filter(e) {
    e.preventDefault();

    const params = new FormData(this.formTarget);

    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.style.display = "block";
    }

    const { response } = await makeFetchRequest(this.formTarget.action, {
      method: "put",
      body: params,
      headers: defaultJSONHeaders,
    }).then(convertResponse);

    this.resourcesTarget.innerHTML = response.html;
  }
}
