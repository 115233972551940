/* global CKEDITOR */
import { Controller } from "stimulus";

const initialiseCKEditor = (domElemId) => {
  // CKEDITOR.stylesSet.add("LPC_Styles", [
  //   { name: "White on Midnight", element: "span", attributes: { "class": "white-on-midnight" } },
  //   { name: "Midnight on Transparent", element: "span", attributes: { "class": "midnight-on-transparent" } },
  //   { name: "Midnight on Goldenrod", element: "span", attributes: { "class": "midnight-on-goldenrod" } },
  // ])
  //
  // CKEDITOR.replace(domElemId, {
  //   stylesSet: "LPC_Styles",
  // })
  CKEDITOR.stylesSet.add("my_custom_style", [
    { name: "My Custom Block", element: "h3", styles: { color: "blue" } },
    {
      name: "My Custom Inline",
      element: "span",
      attributes: { class: "mine" },
    },
  ]);
  // This code is for when you start up a CKEditor instance
  CKEDITOR.replace(domElemId, {
    uiColor: "#9AB8F3",
    stylesSet: "my_custom_style",
    extraPlugins: "attach",
  });
};

export default class extends Controller {
  static targets = ["ckeditor"];

  connect() {
    [...this.ckeditorTargets.map(({ id }) => initialiseCKEditor(id))];
  }
}
