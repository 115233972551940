import humps from "humps";
import { normalize } from "normalizr";

export const defaultJSONHeaders = {
  Accept: "application/json",
};

export const convertRequest = (data) => humps.decamelizeKeys(data);
export const convertResponse = (data) => humps.camelizeKeys(data);

// Normalize a certain set of data
export const normalizeData = (data, schema) =>
  normalize(convertResponse(data), schema);
// Generally normalize a response
export const normalizeResponse = (response, schema) => ({
  response: normalizeData(response.data, schema),
});

// Some default response functions
export const handleResponse = async (response) => {
  const data = await response.json();

  return { response: convertResponse(data) };
};
export const handleError = async (error) => {
  const errorData = await error.json();

  return { errors: convertResponse(errorData.errors) };
};

// Helper function for making fetch requests
export const makeFetchRequest = async (
  url,
  { method, headers, body, params },
) => {
  const token = document
    .getElementsByName("csrf-token")[0]
    .getAttribute("content");

  // Handle params for GET requests by appending them to the URL
  if (params) {
    const urlParams = params.toString();
    url = `${url}?${urlParams}`;
  }

  const config = {
    body,
    method,
    headers: {
      ...defaultJSONHeaders,
      "X-CSRF-Token": token,
      ...headers,
    },
  };

  const response = await fetch(url, config);
  if (!response.ok) {
    throw await handleError(response);
  }

  return handleResponse(response);
};

// Request methods
export const makeDELETERequest = (uri, config = {}) =>
  makeFetchRequest(uri, { method: "DELETE", ...config });

export const makeGETRequest = (uri, config = {}) =>
  makeFetchRequest(uri, { method: "GET", ...config });

export const makePATCHRequest = (uri, body, config = {}) =>
  makeFetchRequest(uri, { method: "PATCH", body, ...config });

export const makePOSTRequest = (uri, body, config = {}) =>
  makeFetchRequest(uri, { method: "POST", body, ...config });

// The most generic API request, to process a request with fetch
export const processRequest = (apiFunc) =>
  apiFunc.then(handleResponse).catch(handleError);
