import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menuToggle"];

  toggleMenu({ currentTarget }) {
    if (currentTarget.classList.contains("is-active")) {
      currentTarget.classList.remove("is-active");
    } else {
      currentTarget.classList.add("is-active");
    }

    const mobileMenu = currentTarget
      .closest(".mobile-header")
      .querySelector(".mobile-nav-menu");
    if (mobileMenu.classList.contains("open")) {
      mobileMenu.classList.remove("open");
    } else {
      mobileMenu.classList.add("open");
    }

    if (document.body.classList.contains("drawer-open")) {
      document.body.classList.remove("drawer-open");
    } else {
      document.body.classList.add("drawer-open");
    }
  }

  toggleVisibility(elem) {
    if (elem.classList.contains("hidden")) {
      elem.classList.remove("hidden");
    } else {
      elem.classList.add("hidden");
    }
  }

  connect() {
    const toggles = document.getElementsByClassName("menu-toggle");

    Array.from(toggles).forEach((toggleElem) => {
      toggleElem.addEventListener("click", () => {
        const menu = toggleElem.closest(".submenu");
        if (menu.classList.contains("open")) {
          menu.classList.remove("open");
        } else {
          menu.classList.add("open");
        }
        if (toggleElem.classList.contains("open")) {
          toggleElem.classList.remove("open");
        } else {
          toggleElem.classList.add("open");
        }
      });
    });

    const togglePlusElements = document.querySelectorAll(".toggle-plus");
    togglePlusElements.forEach((toggle) => {
      toggle.addEventListener("click", this.flipToggleIcon.bind(this));
    });
  }

  flipToggleIcon(event) {
    const clickedIcon = event.currentTarget.querySelector("i");

    // Toggle fa-plus and fa-minus classes on the clicked element
    clickedIcon.classList.toggle("fa-plus");
    clickedIcon.classList.toggle("fa-minus");

    // Reset all other icons
    document.querySelectorAll(".dropdown-toggle i").forEach((icon) => {
      if (icon !== clickedIcon && icon.classList.contains("fa-minus")) {
        icon.classList.remove("fa-minus");
        icon.classList.add("fa-plus");
      }
    });
  }
}
