import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["uploadContainer", "fileInput", "fileName", "removeFile"];

  clearUploadedFiles() {
    this.uploadContainerTarget.classList.replace(
      "fileinput-exists",
      "fileinput-new",
    );
    this.fileNameTarget.innerHTML = "";
    this.fileInputTarget.value = null;
  }

  fileUploaded(e) {
    e.preventDefault();

    this.uploadContainerTarget.classList.replace(
      "fileinput-new",
      "fileinput-exists",
    );

    const { files } = e.target;

    if (files.length > 0) {
      this.fileNameTarget.innerHTML = [...files]
        .map((file) => file.name)
        .join(", ");
    }
  }

  fileRemoved(e) {
    e.preventDefault();

    this.clearUploadedFiles();
  }
}
