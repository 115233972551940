import { Controller } from "stimulus";

const INCLUDED_OFFICE_FIELDS = [
  "template_name",
  "building_name",
  "building_address",
  "grade_of_building",
  "parking",
  "parking_rate",
  "nabers_rating",
  "esg_rating",
  "end_of_trip_facilities",
  "on_site_amenity",
  "location",
  "description",
  "level_suite",
  "lettable_area",
  "highest_area_split",
  "lowest_area_split",
  "net_rental",
  "outgoings",
  "gross_rental",
  "total_gross_annual_rent",
  "fitout_spec_existing_no",
  "fitout_description",
  "common_area_cleaning_cost",
  "premises_cleaning",
  "tenancy_cleaning_cost",
  "timing",
  "floorplan_attached",
  "virtual_tour_link",
  "landlord",
  "building_image",
  "agent_contact_details",
  "lease_type",
  "preferred_lease_term",
  "landlord_details",
];

const REQUIRED_OFFICE_FIELDS = [
  "building_address",
  "end_of_trip_facilities",
  "fitout_spec_existing_no",
  "grade_of_building",
  "gross_rental",
  "landlord_details",
  "location",
  "nabers_rating",
  "net_rental",
  "outgoings",
  "timing",
];

export default class extends Controller {
  static targets = [
    "briefFields",
    "sectorSelectInput",
    "fieldsWrapper",
    "officeFieldsWrapper",
  ];

  sectorChanged(e) {
    if (e.target.selectedOptions[0].label === "Office") {
      INCLUDED_OFFICE_FIELDS.forEach((field) => {
        const elem = this.fieldsWrapperTarget.querySelector(
          `input[type='checkbox'][name*='${field}_include']`,
        );
        if (elem) {
          elem.checked = true;
        }
      });
      REQUIRED_OFFICE_FIELDS.forEach((field) => {
        const elem = this.fieldsWrapperTarget.querySelector(
          `input[type='checkbox'][name*='${field}_required']`,
        );
        if (elem) {
          elem.checked = true;
        }
      });
    } else {
      this.briefFieldsTargets.forEach((field) => {
        const checkboxes = field.querySelectorAll("input[type=checkbox]");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      });
    }
  }
}
