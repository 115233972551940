/* global $ */
import { TempusDominus } from "@eonasdan/tempus-dominus";
import { Controller } from "stimulus";

import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css";

export default class extends Controller {
  static targets = ["publishNow", "publishAt"];

  connect() {
    if (this.hasPublishAtTarget) {
      new TempusDominus(this.publishAtTarget, {
        localization: { format: "dd/MM/yyyy HH:mm" },
        stepping: 30,
      });
    }
  }
}
