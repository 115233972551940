/* global $ */
/* global gon */
import "geocomplete";

export const placesUri = `https://maps.googleapis.com/maps/api/js?key=${gon.google_places_api_key}&libraries=places&language=${gon.locale}`;

// Define which if the result fields to use
const resultsFieldMapping = {
  subpremise: "short_name",
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "short_name",
  postal_code: "short_name",
};

export default (input, options = {}) =>
  $(input)
    .geocomplete(options)
    .bind("geocode:result", (event, { address_components, place_id }) => {
      const components = address_components
        .map((obj) => [obj.types[0], obj[resultsFieldMapping[obj.types[0]]]])
        .reduce((obj, [name, value]) => {
          obj[name] = value;
          return obj;
        }, {});

      // Combine subpremise with street number
      if (components.subpremise) {
        components.street_number = [
          components.subpremise,
          components.street_number,
        ].join("/");
      }

      // Downcase the country
      if (components.country)
        components.country = components.country.toLowerCase();

      if (place_id) components.place_id = place_id;

      $(input).trigger("geocomplete:result", components, event);
    })
    .bind("geocode:error", (error) => {
      console.log(error);
    });
