import { Modal } from "bootstrap";

export default ({
  selector,
  confirmDismiss,
  confirmCallback,
  content,
  title,
}) => {
  const confirmModal = document.querySelector(selector);

  const modalTitle = confirmModal.querySelector(".modal-title");
  const modalBody = confirmModal.querySelector(".modal-body");

  modalTitle.innerHTML = title;
  modalBody.innerHTML = content;

  const bsModal = new Modal(confirmModal);
  bsModal.show();

  const confirmButton = confirmModal.querySelector("button[data-bs-dismiss='ok']");

  const handleConfirmClick = async () => {
    if (confirmCallback) {
      await confirmCallback();

      if (confirmDismiss) {
        bsModal.hide();
      }
    }
  };
  confirmButton.addEventListener("click", handleConfirmClick);

  confirmModal.addEventListener("hide.bs.modal", () => {
    confirmButton.removeEventListener("click", confirmCallback);
  });

  return bsModal;
};
