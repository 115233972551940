import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["videoContentModal", "videoFileModal"];

  embedVideoContent(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const source = this.videoContentModalTarget.querySelector("iframe");
    source.src = currentTarget.dataset.src;
  }

  embedVideoFile(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const source = this.videoFileModalTarget.querySelector("video");
    source.src = currentTarget.dataset.src;
  }

  clearVideoContentModal() {
    const source = this.videoContentModalTarget.querySelector("iframe");
    source.src = "";
  }

  clearVideoFileModal() {
    const source = this.videoFileModalTarget.querySelector("video");
    source.src = "";
  }
}
