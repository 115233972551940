import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["agreedThreshold", "thresholdDefinition"];

  connect() {
    this.thresholdDefinitionTargets.forEach((element) => {
      if (element.checked) {
        this.toggleThresholdDefinition({
          currentTarget: { value: element.value },
        });
      }
    });
  }

  toggleThresholdDefinition(event) {
    if (event.currentTarget.value === "natural") {
      this.agreedThresholdTarget.classList.add("hidden");
    } else {
      this.agreedThresholdTarget.classList.remove("hidden");
    }
  }
}
