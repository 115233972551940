import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pageTitle", "meetTeamFields"];

  connect() {
    this.toggleFields(this.pageTitleTarget.value);
  }

  titleChanged({ target }) {
    this.toggleFields(target.value);
  }

  toggleFields(value) {
    if (value === "meet-the-team") {
      this.meetTeamFieldsTarget.classList.replace("hidden", "block");
    } else {
      this.meetTeamFieldsTarget.classList.replace("block", "hidden");
    }
  }
}
