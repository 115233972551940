import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    Array.from([...this.inputTarget.querySelectorAll(".btn")]).forEach(
      (button) => {
        if (button.querySelector("input[type='checkbox']").checked) {
          button.classList.add("active");
        } else {
          button.classList.remove("active");
        }
      },
    );
  }
}
