import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["clickDisable"];

  connect() {
    Array.from(this.clickDisableTargets).forEach((button) => {
      button.dataset.enableWith = button.value;

      button.addEventListener("click", () => {
        button.value = button.dataset.disableWith;
      });
    });
  }
}
