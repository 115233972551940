import { Controller } from "stimulus";

import {
  convertResponse,
  defaultJSONHeaders,
  makeGETRequest,
} from "~/lib/apiRequests";

export default class extends Controller {
  static targets = [
    "form",
    "input",
    "loadingIndicator",
    "pagination",
    "resources",
  ];

  async filter(e) {
    e.preventDefault();

    const formData = new FormData(this.formTarget);

    const params = new URLSearchParams();
    formData.forEach((value, key) => {
      if (key !== "authenticity_token") {
        params.append(key, value);
      }
    });

    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`,
    );

    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.style.display = "block";
    }

    const { response } = await makeGETRequest(this.formTarget.action, {
      params,
      headers: defaultJSONHeaders,
    }).then(convertResponse);

    this.resourcesTarget.innerHTML = response.resources;
    this.paginationTargets.forEach((elem) => {
      elem.innerHTML = response.pagination;
    });
  }
}
